module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-axe/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-accessibilityjs/gatsby-browser.js'),
      options: {"plugins":[],"injectStyles":"\n        .accessibility-error {\n          box-shadow: 0 0 3px 1px #f00;\n          background-color: rgba(255, 0, 0, 0.25);\n          position: relative;\n        }\n        .accessibility-error:before {\n          content: \"A11Y\";\n          position: absolute;\n          top: 0;\n          left: 0;\n          color: #fff;\n          font-size: 10px;\n          background-color: rgba(255, 0, 0, 0.5);\n          transform: translateY(-100%);\n        }\n      "},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#F98807","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hilderman Photo","short_name":"Hilderman","start_url":"/","lang":"en","background_color":"#fff","theme_color":"#ffb200","display":"minimal-ui","icon":"static/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-143928192-1","head":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
