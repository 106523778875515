export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation) {
    window.locations = window.locations || [document.referrer]
    locations.push(window.location.href)
    window.previousPath = locations[locations.length - 2]
  }
}

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    'This application has been updated. ' +
    'Reload to display the latest version?',
  )

  if (answer === true) {
    window.location.reload()
    window.localStorage.clear()
  }
}
